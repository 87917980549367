import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import logo from '../../images/LogoFB.png';
import MenuP from '../Menus/MenuP';
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { IconContext } from "react-icons"
import Presentacion from '../../Presentacion';
import Pasos from '../Pasos';
import ITN from '../ITN';
import LLC from '../LLC';
import EIN from '../EIN';
import Comprar from '../Comprar/Comprar';
import Rentar from '../Rentar/Rentar';

function App() {
  return (
    <BrowserRouter>
      <div className='container-fluid  col-12 col-md-10 col-lg-8'>
        <div className='row text-white fondoN py-2' style={{fontSize:'0.8rem'}}>
          <div className='col-12 col-md-4  justify-content-md-center'>
            <img src={logo} alt='Logo M&P Group Investment' />
          </div>
          <div className='col-12 col-md-8'>
            <IconContext.Provider value={{ size:"1.5rem", color: "white"}}>
                <div className='row text-end py-2'>
                  <div className='col-12 text-end'><p><span><FaWhatsapp /> </span>+1 (954)-326-3152  </p></div>
                  <div className='col-12 text-end'><p><span><FaEnvelope /></span> mpgroupinvestment@mpgroupinvestment.com</p> </div>
                </div>
            </IconContext.Provider>
          </div>
        </div>
        <div className='row fondo py-2'>
          <MenuP />
        </div>
          <Routes>
            <Route exact path='/' element={<Presentacion />} />
            <Route path='/pasos' element={<Pasos />} />
            <Route path='/comprar' element={<Comprar />} />
            <Route path='/rentar' element={<Rentar />} />
            <Route path='/itn' element={<ITN />} />
            <Route path='/llc' element={<LLC />} />
            <Route path='/ein' element={<EIN />} />
          </Routes>
        <div className='row fondo py-2'>
          <p className='text-center text-white' style={{fontSize:'0.8rem'}}>Derechos reservados M&P Group Investment</p>
        </div>
      </div>
      
    </BrowserRouter>
  );
}

export default App;
