import './rentar.css';
import imgRentar from '../../images/Rentar.png';
function Rentar(){

    return(
        <div className='row fondo'>
            <div className="col-12">
                <div className='imgSource'>
                    <img className="img-fluid border border-0" p-md-auto src={imgRentar} alt='Comprar' width={'100%'} />
                    
                        <a className='imgSource' href="https://www.freepik.es/foto-gratis/palmeras-edificios-san-diego_10352351.htm#query=house%20miami&position=13&from_view=search&track=ais">Imagen de TravelScape</a><span className='text-white'> en Freepik</span>
                    
                </div>
            </div>
        </div>
    )
}

export default Rentar;