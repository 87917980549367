

function LLC() {
    return(
        <>
            <h4 className="mt-2">Compañía de Responsabilidad Limitada (LLC)</h4>
            <p>Una Compañía de Responsabilidad Limitada (LLC, por sus siglas en inglés) es una estructura de negocio permitido 
                conforme a los estatutos estatales. Cada estado puede utilizar regulaciones diferentes y usted debe verificar 
                con su estado si está interesado en iniciar una Compañía de Responsabilidad Limitada.</p>

            <p>Los dueños de las LLC se llaman miembros. La mayoría de los estados no restringen la propiedad, por lo que los miembros 
                pueden incluir individuos, sociedades anónimas, otras LLC y entidades extranjeras. No hay un límite máximo de miembros. 
                La mayoría de los estados también permiten las LLC de un “miembro único”, las que tienen un solo dueño.</p>

            <p>Algunas clases de negocios generalmente no pueden ser LLC, tales como los bancos y las compañías de seguros. Consulte los 
                requisitos de su estado y las normas del impuesto federal para más información. Existen reglas especiales para las LLC 
                extranjeras.</p>

            <h5>Clasificaciones</h5>
            <p>Dependiendo de las elecciones realizadas por la LLC y el número de miembros, el IRS tratará a una LLC como una sociedad 
                anónima, sociedad colectiva, o como parte de la declaración de impuestos del dueño de la LLC (una “entidad excluida”). 
                Específicamente, una LLC doméstica con al menos dos miembros, se clasifica como una sociedad colectiva para los propósitos 
                del impuesto federal sobre los ingresos, a menos que presente el Formulario 8832 y elige afirmativamente para ser tratada 
                como una sociedad anónima. Una LLC con un solo miembro, es tratada como una entidad excluida como separada de su dueño para 
                los propósitos de los impuestos sobre los ingresos  a menos que presente el Formulario 8832 y elija  ser tratada como una 
                sociedad anónima. Sin embargo, a los fines del impuesto sobre la nómina y ciertos impuestos sobre el uso y consumo, una LLC 
                con un solo miembro todavía se considera una entidad separada.</p>

            <p style={{fontSize:'0.8em'}}>Fuente: <a href='https://www.irs.gov/es/businesses/small-businesses-self-employed/limited-liability-company-llc'>https://www.irs.gov/es/businesses/small-businesses-self-employed/limited-liability-company-llc</a></p>        
        </>
    )
}

export default LLC;
