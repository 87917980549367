import { NavLink } from 'react-router-dom';
import './Menu.css'

function MenuP () {

    return(
        <div className='row justify-content-end'>
            <nav className="navbar navbar-expand-lg fondo" data-bs-theme="dark" >
                <div className="container-fluid">
                    <div className="col-12 col-md-5 align-self-end">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
                    </div>
                    <div className="col-12 align-self-end">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto">
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/">Inicio</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/pasos">Pasos</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Más
                                    </NavLink>
                                    <ul className="dropdown-menu">
                                        <li><NavLink className="dropdown-item" to="/comprar">Comprar</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/rentar">Rentar/Aluilar</NavLink></li>
                                        {/*<li><NavLink className="dropdown-divider" /></li>
                                        <li><NavLink className="dropdown-item" to="/itn">¿Qué es un ITN?</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/llc">¿Qué es un LLC?</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ein">¿Qué es una EIN?</NavLink></li>
                                        */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default MenuP;