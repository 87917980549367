import Oportunidades from "../componets/Oportunidades";
import SobreNosotros from "../componets/SobreNosotros";

function Presentacion(){
    return (
        <div className='row fondo'>
          
          <div className='col-12 col-md-6 p-0 p-md-2 text-white'>
            <Oportunidades />
          </div>
          <div className='col-12 col-md-6 p-0  p-md-2 text-white '>
            <SobreNosotros />
          </div>
        </div>
    )
}

export default Presentacion;