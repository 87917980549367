import imgGanancias from './../../images/Ganancias.png';
import { NavLink } from 'react-router-dom';

function SobreNosotros(){
    return(
        <div className='imgSource'>
            <NavLink to='/pasos'>
                <img className="img-fluid border border-0" p-md-auto src={imgGanancias} alt='Disfrute'  max-width = {"100%"} />
            </NavLink>
            <a href="https://www.freepik.es/foto-gratis/escena-noche-orlando_26741026.htm#query=Fort%20Lauderdale&position=11&from_view=search&track=ais">Imagen de TravelScape</a> en Freepik
        </div>
    )
}

export default SobreNosotros;