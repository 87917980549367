import './comprar.css';
import imgComprar from '../../images/Comprar.png';
function Comprar(){

    return(
        <div className='row fondo'>
            <div className="col-12">
                <div className='imgSource'>
                    <img className="img-fluid border border-0" p-md-auto src={imgComprar} alt='Comprar' width={'100%'} />
                    
                        <a className='imgSource' href="https://www.freepik.es/foto-gratis/arquitectura-urbana-ciudad_30010850.htm#query=house%20fort%20Lauderdale&position=5&from_view=search&track=ais">Imagen de TravelScape</a><span className='text-white'> en Freepik</span>
                    
                </div>
            </div>
        </div>
    )
}

export default Comprar;