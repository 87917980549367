import imgOportunidades from '../../images/Oportunidades.png'
import './oportunidades.css'

function Oportunidades (){
    return(
        <div className='imgSource'>
            <img className="img-fluid border border-0" p-md-auto src={imgOportunidades} alt='Oportunidades'  />
            
                <a className='imgSource' href="https://www.freepik.es/foto-gratis/escena-noche-miami_29453150.htm#query=Fort%20Lauderdale&position=2&from_view=search&track=ais">Imagen de TravelScape</a> en Freepik
            
        </div>
    )
}

export default Oportunidades;

