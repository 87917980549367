import ReactPlayer from 'react-player/youtube'

function ITN(){
    return(
        <>
            <p><h4>¿Qué es un ITIN?</h4></p>

            <p>El Número de Identificación Personal del Contribuyente (ITIN, por sus siglas en inglés) es un número de tramitación de impuestos 
                emitido por el Servicio de Impuestos Internos. El IRS emite los ITIN a las personas a quienes se les requiere tener un número de 
                identificación del contribuyente de los Estados Unidos.</p>

            <p>El IRS emite los ITIN para ayudar a la gente a cumplir con las leyes tributarias de los Estados Unidos y para proporcionar a la gente
                que no reúne los requisitos para obtener un número de Seguro Social, una manera eficiente de tramitar y dar cuenta de sus declaraciones
                de impuestos y sus pagos. Se emiten independientemente del estado migratorio, ya que tanto los extranjeros residentes como los no 
                residentes pueden verse obligados a presentar una declaración de impuestos o una declaración informativa de los Estados Unidos, 
                conforme al Código de Impuestos Internos. Los ITIN no sirven para ningún otro propósito que no sea la presentación de declaraciones del 
                impuesto federal.</p>

            <p><h4>Un ITIN no:</h4></p>
            <ul>
                <li>Autoriza a trabajar en los Estados Unidos</li>
                <li>Proporciona el derecho a recibir los beneficios de Seguro Social</li>
                <li>Califica a un dependiente para propósitos del crédito por ingreso del trabajo </li>
            </ul>
            <br />
            <p><h4>¿Necesito un ITIN?</h4></p>
            <p>Usted debe obtener un ITIN si:</p>
                <ol>
                    <li>No tiene un Número de Seguro Social, ni reúne los requisitos para obtenerlo, y</li>
                    <li>Tiene la obligación de proporcionar un número de identificación del impuesto federal, o de presentar una declaración del impuesto federal, y</li>
                    <li>Usted está en una de las siguientes categorías:</li>
                        <ul>
                            <li>Es un extranjero no residente con obligación de presentar una declaración de impuestos estadounidense.</li>
                            <li>Es un extranjero residente estadounidense (basado en los días que estuvo presente en los EE. UU.) que presenta una declaración de impuestos estadounidense.</li>
                            <li>Es el dependiente o cónyuge de un ciudadano/extranjero residente de los Estados Unidos.</li>
                            <li>Es el dependiente o cónyuge de un extranjero no residente que tiene un visado.</li>
                            <li>Es un extranjero no residente que reclama un beneficio de un tratado tributario.</li>
                            <li>Es un extranjero no residente estudiante, profesor o investigador que presenta una declaración del impuesto estadounidense o reclama una excepción.</li>
                        </ul>
                </ol>
            <p style={{fontSize:'0.8em'}}>Fuente: <a href='https://www.irs.gov/es/individuals/individual-taxpayer-identification-number'>
                https://www.irs.gov/es/individuals/individual-taxpayer-identification-number</a>
            </p>
            <div className='row mt-5'>
                <div className='col-12 col-6 m-auto'>
                    <ReactPlayer 
                        url='https://www.youtube.com/watch?v=s5Ere5zJ9yk&t=23s'  
                        controls = 'true'
                        width='100%'
                    />
                </div>
            </div>
        </>
    )
}

export default ITN;