

function EIN() {
    return(
        <>
            <h3 className="mt-2">Números de Identificación del Empleador</h3>
            <p>El número de identificación del empleador (EIN, por sus siglas en inglés) se conoce 
                también como el número de identificación para el impuesto federal, y se usa para identificar 
                a una entidad comercial. Por lo general, los negocios necesitan un EIN.
            </p>
            <p>Usted puede solicitar un EIN de varias maneras, y ahora puede solicitarlo en línea. Este servicio 
                gratuito es ofrecido por el Servicio de Impuestos Internos y usted puede obtener su EIN de 
                inmediato. Usted debe verificar con su estado para asegurarse si necesita un número o escritura 
                de constitución estatal
            </p>

            <h4>¿Necesita un EIN?</h4>
            <p>Usted necesita un número de Identificación del Empleador (EIN, por sus siglas en inglés) si responde 
                "Sí" a alguna de las siguientes preguntas.
            </p>
            <ul>
                <li>¿Tiene empleados?</li>
                <li>¿Opera su negocio como sociedad anónima o sociedad colectiva?</li>
                <li>¿Presenta declaraciones de alguna de estas clases de impuestos: nómina, uso y consumo, o alcohol, tabaco y armas de fuego?</li>
                <li>¿Retiene impuestos sobre los ingresos, sin incluir los sueldos pagados a un extranjero no residente?</li>
                <li>¿Tiene un plan Keogh?</li>
                <li>¿Participa usted en alguna de las siguientes clases de organizaciones?</li>
                    <ul>
                        <li>Fideicomisos, excepto ciertos fideicomisos revocables por el cesionista, Arreglos de Ahorros para la Jubilación (IRA, 
                            por sus siglas en inglés), declaraciones de impuestos de negocios para una organización exenta</li>
                        <li>Caudales hereditarios</li>
                        <li>Entidades agrupadoras de inversiones en hipotecas de bienes raíces</li>
                        <li>Organizaciones sin fines de lucro</li>
                        <li>Cooperativas agropecuarias</li>
                        <li>Administradores de planes</li>
                    </ul>
            </ul>
            
            <p style={{fontSize:'0.8em'}}>Fuente: <a href='https://www.irs.gov/es/businesses/small-businesses-self-employed/employer-id-numbers'>https://www.irs.gov/es/businesses/small-businesses-self-employed/employer-id-numbers</a></p>
        </>
    )
}

export default EIN;