import { IconContext } from "react-icons"
import { FaList, FaWhatsapp } from "react-icons/fa";
import { NavLink } from "react-router-dom";

function Pasos() {

    return(
        <IconContext.Provider value={{ size:"1em", color: "white"}}>
            <div className="row fondo pt-2">
                <div className="col-md-2 d-none d-md-inline"></div>
                <div className="col-12 col-md-8"> 
                    <div className="row">

                    </div>
                    <div className="row text-white">
                        <h3>Compra de una propiedad <span><FaList /></span> </h3> 
                        
                        <ul>
                            <li>Definición de sus objetivos</li>
                            <li>Elegir una propiedad</li>
                            <li>Firma del contrato compra/venta</li>
                            <li>Envío de la seña</li>
                            <li>Estudio del titulo de la propiedad</li>
                            <li>Cierre de la compra</li>
                            <li>Recibir las escrituras</li>
                        </ul>
                    </div>
                    <div className="row text-white">
                        <hr />
                        <h3>Alquilar una propiedad <span><FaList /></span></h3>
                        <ul>
                            <li>Estudio de mercado</li>
                            <li>Definición del precio del alquiler</li>
                            <li>Estudio de las ofertas y los oferentes</li>
                            <li>Elección de inquilino</li>
                            <li>Firma del contrato</li>
                            <li>Controlar la ejecución del alquiler</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-2 d-none d-md-inline"></div>
            </div>
        </IconContext.Provider>
    )
}

export default Pasos;